import React, {useMemo} from 'react';
import {StepHeader} from './StepHeader';
import {DynamicStep} from './DynamicStep';
import {useControllerProps} from '../../../Widget/ControllerContext';
import {StepState} from '../../../../../types/app.types';

export interface StepProps {
  index: number;
  title: string;
  dataHook: string;
  classNameDynamicStep?: string;
  classNameStepHeader?: string;
  collapsedComponent?: () => JSX.Element;
  openComponent: (props: any) => JSX.Element;
  emptyComponent?: () => JSX.Element;
}

export enum StepDataHooks {
  header = 'StepDataHooks.header',
}

export const Step = ({
  index,
  title,
  dataHook,
  classNameDynamicStep,
  classNameStepHeader,
  openComponent: OpenComponent,
  collapsedComponent: CollapsedComponent,
  emptyComponent: EmptyComponent,
}: StepProps) => {
  const {
    stepsManagerStore: {stepsList},
  } = useControllerProps();

  const stepState = stepsList[index].state;

  const StepInternal = useMemo(
    () =>
      /* eslint-disable-next-line react/display-name */
      () => {
        return (
          <>
            <StepHeader index={index} label={title} dataHook={StepDataHooks.header} className={classNameStepHeader} />
            {/* istanbul ignore next */ stepState === StepState.EMPTY && EmptyComponent && <EmptyComponent />}
            {stepState === StepState.OPEN && <OpenComponent />}
            {stepState === StepState.COLLAPSED && CollapsedComponent && <CollapsedComponent />}
          </>
        );
      },
    [EmptyComponent, CollapsedComponent, OpenComponent, title, index, stepState, classNameStepHeader]
  );

  return <DynamicStep index={index} dataHook={dataHook} component={StepInternal} className={classNameDynamicStep} />;
};
