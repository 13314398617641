import React, {useState} from 'react';
import {LineItemSection} from '../LineItemSection/LineItemSection';
import {CouponInput} from '../CouponInput/CouponInput';
import {GiftCardInput} from '../GiftCardInput/GiftCardInput';
import {useControllerProps} from '../../Widget/ControllerContext';
import {classes} from '../Summary.st.css';
import {TotalsSection} from '../TotalsSection/TotalsSection';
import {ViolationsAndPlaceOrderButton} from '../../ViolationsAndPlaceOrderButton/ViolationsAndPlaceOrderButton';
import {StepId, ViolationsAndPlaceOrderButtonLocation} from '../../../../types/app.types';
import {SecureCheckout} from '../SecureCheckout/SecureCheckout';
import {CheckoutSlot} from '../../CheckoutSlot';
import {SlotId} from '../../../../domain/utils/slotId';
import {SPECS} from '../../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';
import {BottomFoldableSummarySection} from '../BottomFoldableSummarySection/BottomFoldableSummarySection';
import {isLineItemErrorViolationExist} from '../LineItem/LineItem.utils';
import {BiFoldableSummaryAction, BiMobilePosition} from '../../../../domain/utils/bi.util';

export enum BottomMobileSummaryDataHooks {
  placeOrderEmpty = 'BottomMobileSummaryDataHooks.placeOrderEmpty',
  paymentAndPlaceOrderStep = 'BottomMobileSummaryDataHooks.paymentAndPlaceOrderStep',
  placeOrderFastFlow = 'BottomMobileSummaryDataHooks.placeOrderFastFlow',
}
export interface BottomMobileSummaryProps {
  dataHook: string;
  checkboxesRef?: React.RefObject<HTMLDivElement>;
}

/* eslint-disable sonarjs/cognitive-complexity */
export const BottomMobileSummary = ({dataHook, checkboxesRef}: BottomMobileSummaryProps) => {
  const {experiments} = useExperiments();
  const {
    checkoutStore: {
      checkout,
      shouldShowGiftCardSection,
      shouldShowCouponSection,
      isFastFlow,
      shouldShowViolations,
      onMobileFoldableSummaryToggle,
      hasPartialOutOfStockError,
    },
    stepsManagerStore: {activeStep},
  } = useControllerProps();

  const shouldShowViolationsAndPlaceOrderButton = isFastFlow || activeStep.stepId === StepId.paymentAndPlaceOrder;
  const addSlotToCheckout = !isFastFlow && experiments.enabled(SPECS.AddSlotToCheckout);
  const shouldMoveSlotAboveCheckoutButton = /* istanbul ignore next */ experiments.enabled(
    SPECS.MoveSlotAboveCheckoutButton
  );
  const hasLineItemIssue =
    !!checkout.errors.noItemsError || (shouldShowViolations && isLineItemErrorViolationExist(checkout.violations));
  const [isBottomFoldableSummarySectionOpen, setIsBottomFoldableSummarySectionOpen] = useState(
    hasLineItemIssue || !!hasPartialOutOfStockError
  );

  const hasMoreThanOneLineItem = checkout.lineItems.length > 1;
  const shouldShowBottomFoldableSummarySection =
    hasMoreThanOneLineItem || experiments.enabled(SPECS.ShouldAlwaysCollapseBottomSummary);
  const shouldShowLineItemSection =
    (!experiments.enabled(SPECS.ShouldAlwaysCollapseBottomSummary) && !hasMoreThanOneLineItem) ||
    isBottomFoldableSummarySectionOpen;
  const toggleFoldableSummarySection = () => {
    const isSummaryOpen = !isBottomFoldableSummarySectionOpen;
    setIsBottomFoldableSummarySectionOpen(isSummaryOpen);
    onMobileFoldableSummaryToggle(BiMobilePosition.bottom, {
      action: isSummaryOpen ? BiFoldableSummaryAction.open : BiFoldableSummaryAction.close,
    });
  };
  return (
    <div data-hook={dataHook} className={classes.bottomMobileSummaryRoot}>
      <div className={classes.bottomMobileSummaryContainer}>
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot
            className={classes.BeforeSummaryCheckoutSlot}
            slotId={SlotId.BeforeSummaryCheckoutSlot}
          />
        )}
      </div>
      {shouldShowBottomFoldableSummarySection && (
        <BottomFoldableSummarySection
          onClick={toggleFoldableSummarySection}
          isOpen={isBottomFoldableSummarySectionOpen}
        />
      )}
      <div className={classes.bottomSummaryWithFoldableSection}>
        {shouldShowLineItemSection && <LineItemSection />}
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot
            className={classes.AfterLineItemsSummaryCheckoutSlot}
            slotId={SlotId.AfterLineItemsSummaryCheckoutSlot}
          />
        )}
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot
            className={classes.AfterLineItems2SummaryCheckoutSlot}
            slotId={SlotId.AfterLineItems2SummaryCheckoutSlot}
          />
        )}
        <div className={classes.couponAndGiftCard}>
          {shouldShowCouponSection && <CouponInput mobilePosition={BiMobilePosition.bottom} />}
          {shouldShowGiftCardSection && <GiftCardInput mobilePosition={BiMobilePosition.bottom} />}
        </div>
        {addSlotToCheckout && (
          /* istanbul ignore next: test slot */ <CheckoutSlot
            className={classes.BeforeTotalsBreakdownSummaryCheckoutSlot}
            slotId={SlotId.BeforeTotalsBreakdownSummaryCheckoutSlot}
          />
        )}
        <TotalsSection />
      </div>
      <div className={classes.bottomMobileSummaryContainer}>
        {
          /* istanbul ignore next: test slot */ addSlotToCheckout && shouldMoveSlotAboveCheckoutButton ? (
            <CheckoutSlot className={classes.AfterSummaryCheckoutSlot} slotId={SlotId.AfterSummaryCheckoutSlot} />
          ) : null
        }
        {shouldShowViolationsAndPlaceOrderButton && (
          <ViolationsAndPlaceOrderButton
            location={ViolationsAndPlaceOrderButtonLocation.summary}
            checkboxesRef={checkboxesRef}
          />
        )}
        <SecureCheckout />
        {
          /* istanbul ignore next: test slot */ addSlotToCheckout && !shouldMoveSlotAboveCheckoutButton ? (
            <CheckoutSlot
              className={classes.AfterSummaryCheckoutSlotSlotBelowCheckout}
              slotId={SlotId.AfterSummaryCheckoutSlot}
            />
          ) : null
        }
      </div>
    </div>
  );
};
